export enum ChainId {
  MAINNET = 169,
  TESTNET = 59140,
}

export const NETWORK_URLS: {
  [chainId in ChainId]: string
} = {
  [ChainId.MAINNET]: `https://pacific-rpc.manta.network/http`,
  [ChainId.TESTNET]: `https://rpc.goerli.linea.build`,
}
